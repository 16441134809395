import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container, Row, Col } from '@zd/components/grid';
import Button from '@zd/components/button';
import Link from '@zd/components/link';
import LazyImage from '@zd/components/lazy-image';
import theme from '@zd/theme';

const Div = styled.div`
  margin-top: 1rem;

  ${theme.medias.gteLarge} {
    margin-top: 3rem;
  }
`;

const ContentDiv = styled.div`
  position: relative;
  z-index: ${theme.layers.heroContent};
`;

const TitleH1 = styled.h1`
  color: ${theme.colors.primaryBlue};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  & > small {
    display: block;
  }

  ${theme.medias.gteLarge} {
    margin-top: 0;
    text-align: left;
    font-size: 40px;
    line-height: 52px;
    max-width: 480px;
  }
`;

const DividerDiv = styled.div`
  width: 100px;
  height: 5px;
  background: ${theme.colors.secondaryBlue};
  margin: 0 auto;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const DescriptionP = styled.p`
  color: ${theme.colors.mainGrey};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    text-align: left;
    font-size: 18px;
    line-height: 32px;
  }
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > *:first-of-type {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  ${theme.medias.gteLarge} {
    text-align: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > *:first-of-type {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
`;

const StyledLazyImage = styled(LazyImage)`
  z-index: ${theme.layers.heroImage};

  ${theme.medias.gteLarge} {
    transform: scale(1.35);
  }
`;

const Hero = props => {
  const { fluidImage, ...rest } = props;
  return (
    <Div {...rest}>
      <Container>
        <Row>
          <Col lg="7">
            <ContentDiv>
              <TitleH1>
                A sua Clinica de Saúde Mental 100% Online.
                <small>Sem esperas. Sem atrasos.</small>
              </TitleH1>
              <DividerDiv />
              <DescriptionP>
                Problemas todas as pessoas têm, saber lidar com eles é que já não é bem assim. Ser
                feliz é uma arte! Essa arte implica sempre um certo grau de desenvolvimento pessoal.
                <br />
                <br />A vida é curta demais, para não ser a melhor versão de si mesmo. Seja a
                personagem principal da sua vida, marque a sua consulta ainda hoje.
              </DescriptionP>
              <BottomDiv>
                <Link href="/marcar-consulta?type=online" title="Marcar consulta online">
                  <Button color="primary" title="Marcar consulta online">
                    Marcar consulta online
                  </Button>
                </Link>
                {/*
                <Link href="/marcar-consulta?type=presencial" title="Marcar consulta presencial">
                  <Button color="secondary" title="Marcar consulta presencial">
                    Marcar consulta presencial
                  </Button>
                </Link>
                */}
              </BottomDiv>
            </ContentDiv>
          </Col>
          <Col lg="5">
            <StyledLazyImage
              fluid={fluidImage}
              alt="Dr. João Vilas Boas"
              title="Dr. João Vilas Boas"
            />
          </Col>
        </Row>
      </Container>
    </Div>
  );
};

Hero.propTypes = {
  fluidImage: PropTypes.any.isRequired,
};

export default Hero;
