import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Carousel from '@zd/components/carousel';

import theme from '@zd/theme';

const WrapperDiv = styled.div``;

const ContentDiv = styled.div`
  width: 100%;
  order: 2;
`;

const TitleH2 = styled.h2`
  color: ${theme.colors.primaryBlue};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    font-size: 40px;
    line-height: 52px;
  }
`;

const DividerDiv = styled.div`
  width: 100px;
  height: 5px;
  background: ${theme.colors.secondaryBlue};
  margin: 0 auto;
  margin-bottom: 1rem;
`;

const DescriptionP = styled.p`
  color: ${theme.colors.mainGrey};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;

  ${theme.medias.gteLarge} {
    font-size: 18px;
    line-height: 32px;
  }
`;

const AuthorP = styled.p`
  color: ${theme.colors.primaryBlue};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;

  ${theme.medias.gteLarge} {
    font-size: 16px;
    line-height: 32px;
  }
`;

const Div = styled.div`
width: 100%;
height: 300px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 0;
transform: translateY(5%);

${theme.medias.gteSmall} {
  height: 200px;
}

${theme.medias.gteMedium} {
  height: 300px;
}

${theme.medias.gteLarge} {
  height: 300px;
}
`;

const ITEMS = [
  {
    author: 'Anónimo',
    content:
      'O Dr. João é um exemplo de profissional. É muito dedicado aos seus pacientes. Indico a todos.',
  },
  {
    author: 'Anónimo',
    content:
      'O Dr. João é muito atencioso e explica tudo detalhadamente! Excelente médico,  gostei muito da consulta!',
  },
  {
    author: 'Anónimo',
    content:
      'Como sempre, a consulta foi maravilhosa! Profissional super competente e atencioso! Estou a gostar muito do resultado do tratamento!',
  },
  {
    author: 'Anónimo',
    content:
      'Ao iniciar o meu tratamento não via uma luz no fim do túnel. Após quatro meses de tratamento estou muito bem e a melhorar a cada dia.',
  },
  {
    author: 'Anónimo',
    content:
      'Muito competente, paciente e atencioso, não tem pressa em acabar a consulta e tem-me ajudado muito.',
  },
  {
    author: 'Anónimo',
    content: 'Excelente profissional, atencioso, acolhedor! Foi um anjo que caiu do céu.',
  },
  {
    author: 'Anónimo',
    content:
      'Consulta impecável! Gostei muito do atendimento, das explicações e do carinho!!! Profissional super competente!',
  },
  {
    author: 'Anónimo',
    content:
      'O Dr. João é incrível! Super atencioso, interessa-se pela nossa vida. Explica tudo detalhadamente. Recomendo muito!',
  },
  {
    author: 'Anónimo',
    content:
      'Excelente profissional, atencioso. Que Deus continue a abençoar o seu trabalho e a sua vida. Parabéns por tudo.',
  },
  {
    author: 'Anónimo',
    content: 'Muito atencioso, transmite confiança, muito capaz!',
  },
  {
    author: 'Anónimo',
    content:
      'Excelente! Atencioso, atento e interessado. Vale muito a pena o investimento, sem dúvida. Em breve voltarei.',
  },
  {
    author: 'Anónimo',
    content:
      'Excelente atendimento, sem a pressa habitual de outros médicos de acabar a consulta, ouviu e esclareceu todas as dúvidas.',
  },
  /*
  {
    author: 'Anónimo',
    content:
      'O Dr. João me foi indicado por uma amiga. A minha mãe já tinha passado por outros profissionais sem sucesso. Estamos satisfeitas com o profissional.',
  },
  {
    author: 'Anónimo',
    content:
      'Atendimento atencioso e eficaz. O Dr. possui uma conduta tranquila e de muita empatia. Mesmo com a telemedicina, atendeu prontamente as minhas expectativas.',
  },
  {
    author: 'Anónimo',
    content:
      'Sou grata de coração ao Dr. João. Acertou na medicação, tive uma excelente recuperação que me livrou de um abismo. Que receba a dobrar o bem que me fez e ainda me faz. Excelente profissional!!! Obrigada!',
  },
  {
    author: 'Anónimo',
    content:
      'Não tenho palavras para agradecer por tudo o que O Dr. João fez por mim e pela minha família. Excelente profissional! Ajudou-nos muito no momento mais difícil das nossas vidas, quando achávamos que não havia solução.',
  },
  {
    author: 'Anónimo',
    content:
      'O Dr. João é muito culto, bastante atencioso e profissional! Quando vou às consultas, sinto-me protegida, tamanha a sua atenção, respeito, humanidade e orientações certas para me ver sempre bem de saúde! Recomendo, sem dúvidas!',
  },
  {
    author: 'Anónimo',
    content:
      'A minha primeira consulta foi na semana passada. Posso afirmar que o Dr. João é um ser humano e profissional com extrema empatia, atencioso e atento aos detalhes. Estou confiante que tudo ficará ajustado. Sinto gratidão pela sua atenção e competência.',
  },
  {
    author: 'Anónimo',
    content:
      'O Dr. João tem uma maneira altamente impactante de mesclar perguntas e reflexões de apoio. As suas habilidades e compaixão ajudaram-me a lidar com o momento mais angustiante da minha vida. Conversando com O Dr. João, fui capaz de esclarecer as coisas, saber que tinha uma escolha, saber que estaria bem e experimentar perdas e dores muito significativas.',
  },
  {
    author: 'Anónimo',
    content:
      'Já tive acompanhamento psiquiátrico com outros profissionais, e nenhum foi tão atencioso e humano como o Dr. João. O que eu gosto do tratamento dele é que, ele não gosta de enfiar medicamentos no paciente e de o deixar escravo de remédios! Ele quer resultado e ver resposta e o melhor para o seu paciente, conforto e bem estar. Estou muito grata por tudo. Eu vivo e sou feliz e segura, reconheço-me e entendo as minhas dores . Recomendo para quem precisar.',
  },
  {
    author: 'Anónimo',
    content:
      'Trabalhar com o Dr. João sempre foi um exercício construtivo. Ele tem uma capacidade incrível de realmente ouvir e, em momentos-chave, faz perguntas muito relevantes e pertinentes para ajudá-lo a entender melhor as suas ações, padrões de pensamento ou as histórias que conta. Através das nossas sessões, fui capaz de desenvolver mais autoconsciência, ganhar perspectiva e de me desafiar a abordar certas noções ou comportamentos passados. Foi uma experiência verdadeiramente terapêutica e construtiva pela qual eu nunca poderia ter passado sem a ajuda dele. Obrigado!',
  },
  {
    author: 'Anónimo',
    content:
      'Quando iniciei minhas sessões com o Dr. João ele adaptou a sua abordagem às minhas necessidades e circunstâncias de uma maneira muito profissional, procurando genuinamente obter o melhor resultado de nossas sessões. As nossas discussões sempre foram muito abertas e esclarecedoras e desafiaram-me de maneira positiva. Ele é muito confiável e compreensivo. Senti-me muito bem durante e após as sessões, também em tempos difíceis; era um espaço para compartilhar e refletir. Recomendo o Dr. João para pessoas como eu, que queiram encontrar um espaço semelhante e explorá-lo com alguma orientação.',
  },
  */
];

const Testimonials = props => {
  const [currentItem, setCurrentItem] = useState(ITEMS[0]);

  return (
    <WrapperDiv {...props}>
      <ContentDiv>
        <TitleH2>Os nossos pacientes, a nossa paixão</TitleH2>
        <DividerDiv />
        <Carousel
          slides={ITEMS.map(item => ({
            captionAlignment: 'center',
            caption: (
              <Div>
                <DescriptionP>{item.content}</DescriptionP>
                <AuthorP>{item.author}</AuthorP>
              </Div>
            ),
          }))}
        />
      </ContentDiv>
    </WrapperDiv>
  );
};

Testimonials.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.object,
  items: PropTypes.array,
};

Testimonials.defaultProps = {
  items: [],
};

export default Testimonials;
