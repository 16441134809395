import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import SubscribeForm from '@zd/components/forms/subscribe-form';
import ItemTile from '@zd/components/item-tile';
import Hero from '@zd/components/hero';
import Testimonials from '@zd/components/testimonials';
import theme from '@zd/theme';

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "dr-joao-vilas-boas-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 1404) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tile01: file(relativePath: { eq: "tile-01.png" }) {
      childImageSharp {
        fluid(maxHeight: 157) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tile02: file(relativePath: { eq: "tile-02.png" }) {
      childImageSharp {
        fluid(maxHeight: 207) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => {
  useEffect(() => {
    document.body.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflowX = 'initial';
    };
  }, []);

  return (
    <>
      <PageSEO {...props} />
      <Hero fluidImage={props.data.heroImage.childImageSharp.fluid} />
      <br />
      <br />
      <br />
      <Container>
        <ItemTile
          title="A terapia on-line é realmente eficaz?"
          description={
            <>
              A eficácia desta prática é cientificamente reconhecida e validada por entidades
              competentes e são cada vez mais aqueles que fazem do aconselhamento e da terapia
              online um aliado na conquista dos seus objetivos.
              <br />
              <br />A Clínica da Boavista oferece-lhe a possibilidade de aceder, online, ao apoio
              psiquiátrico, psicológico e motivacional de que necessita.
            </>
          }
          fluidImage={props.data.tile01.childImageSharp.fluid}
          left={true}
        />
        <br />
        <br />
        <br />
        <ItemTile
          title="A nossa metodologia inovadora"
          description={
            <>
              Realizar terapia é uma escolha inteligente, que promove o auto-conhecimento e o
              bem-estar, proporcionando a construção de uma vida mais saudável e equilibrada. Uma
              vida mais feliz.
              <br />
              <br />
              Na Clínica da Boavista, oferecemos a possibilidade de alcançar estes objetivos,
              online. Através de vídeo-consultas.
              <br />
              <br />
              Sem interrupções no trabalho, sem custos de deslocações, sem atrasos, sem
              complicações.
              <br />
              <br />À hora marcada, onde quer que esteja.
            </>
          }
          fluidImage={props.data.tile02.childImageSharp.fluid}
          left={false}
        />
        <br />
        <br />
        <br />
        <Testimonials />
        <br />
        <br />
        <br />
        <SubscribeForm />
        <br />
        <br />
      </Container>
      {/*<Instagram /> */}
    </>
  );
};
