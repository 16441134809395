import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import BootstrapCarouselItem from 'react-bootstrap/CarouselItem';
import BootstrapCarouselCaption from 'react-bootstrap/CarouselCaption';
import {GoTriangleLeft, GoTriangleRight} from 'react-icons/go'


import theme from '@zd/theme';

const StyledCaption = styled(BootstrapCarouselCaption, {
  shouldForwardProp: prop => prop !== 'captionAlignment',
})`
padding: 0;
  ${({ captionAlignment }) =>
    css`
      text-align: ${captionAlignment};
    `}
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 300px;

  ${theme.medias.gteSmall} {
    height: 300px;
  }

  ${theme.medias.gteMedium} {
    height: 300px;
  }

  ${theme.medias.gteLarge} {
    height: 300px;
  }
`;

const Div = styled.div`
  width: 100%;
  height: 300px;

  ${theme.medias.gteSmall} {
    height: 200px;
  }

  ${theme.medias.gteMedium} {
    height: 300px;
  }

  ${theme.medias.gteLarge} {
    height: 300px;
  }
`;

const IconDiv = styled.div`
  color: ${theme.colors.primaryBlue};

  & > svg {
    width: 40px;
    height: 40px;
  }
`;

const Carousel = props => {
  const { slides, ...rest } = props;

  return (
    <BootstrapCarousel nextLabel="Próximo" prevLabel="Anterior" nextIcon={<IconDiv><GoTriangleRight /></IconDiv>} prevIcon={<IconDiv><GoTriangleLeft /></IconDiv>} {...rest}>
      {slides.map((slide, i) => (
        <BootstrapCarouselItem key={i}>
          {slide.src ?
            <Img className="d-block w-100" src={slide.src} alt={slide.alt} /> :
             <Div />}
          <StyledCaption captionAlignment={slide.captionAlignment || 'center'}>
            {slide.caption}
          </StyledCaption>
        </BootstrapCarouselItem>
      ))}
    </BootstrapCarousel>
  );
};

Carousel.propTypes = {
  indicators: PropTypes.bool,
  controls: PropTypes.bool,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      caption: PropTypes.any,
      captionAlignment: PropTypes.string,
    })
  ),
};

Carousel.defaultProps = {
  indicators: false,
  controls: true,
  slides: [],
};

export default Carousel;
