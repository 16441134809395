import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import LazyImage from '@zd/components/lazy-image';
import theme from '@zd/theme';

const WrapperDiv = styled.div`
  display: block;

  ${theme.medias.gteLarge} {
    display: flex;
  }
`;

const ContentDiv = styled.div`
  width: 100%;
  order: 2;
`;

const TitleH2 = styled.h2`
  color: ${theme.colors.primaryBlue};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    text-align: left;
    font-size: 40px;
    line-height: 52px;
  }
`;

const DividerDiv = styled.div`
  width: 100px;
  height: 5px;
  background: ${theme.colors.secondaryBlue};
  margin: 0 auto;
  margin-bottom: 1rem;

  ${theme.medias.gteLarge} {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const DescriptionP = styled.p`
  color: ${theme.colors.mainGrey};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;

  ${theme.medias.gteLarge} {
    text-align: left;
    font-size: 18px;
    line-height: 32px;
  }
`;

const ImageDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'left',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  margin-top: 2rem;

  ${theme.medias.gteLarge} {
    ${({ left }) =>
      css`
        padding: 0;
        margin-top: 0;
        width: 90%;
        ${left ? 'padding-right: 10%' : 'padding-left: 10%'};
        order: ${left ? 1 : 2};
        justify-content: ${left ? 'flex-start' : 'flex-end'};
      `}
  }
`;

const StyledLazyImage = styled(LazyImage)`
  width: 100%;
`;

const ItemTile = props => {
  const { title, description, fluidImage, left = false, ...rest } = props;

  return (
    <WrapperDiv {...rest}>
      <ContentDiv>
        <TitleH2>{title}</TitleH2>
        <DividerDiv />
        <DescriptionP>{description}</DescriptionP>
      </ContentDiv>
      <ImageDiv left={left}>
        <StyledLazyImage fluid={fluidImage} alt={title} />
      </ImageDiv>
    </WrapperDiv>
  );
};

ItemTile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fluidImage: PropTypes.any,
  left: PropTypes.bool,
};

export default ItemTile;
